<template>
  <div>
    <div class="box">
      <h1>订单异常状态设置</h1>
      <div class="data">
        <h2 class="shuNum">数据显示设置:</h2>
        <div class="option">
          <div style="margin: 15px 0"></div>
          <el-checkbox-group
            v-model="checkedLists"
            @change="handleCheckedListChange"
          >
            <el-checkbox
              v-for="item in listData"
              :label="item.sign"
              :key="item.sign"
              class="drag-item"
              border
            >
              <span>{{ item.name }}</span>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <div class="btn">
          <button class="set">保存</button>
          <button class="cancel">取消</button>
        </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
const form = reactive({
  name: "",
  region: "",
  date1: "",
  date2: "",
  delivery: false,
  type: [],
  resource: "",
  desc: "",
});
export default {
  data() {
    return {
      form,
      listData: [
        { name: "已超时", sign: 111 },
        { name: "未审核", sign: 222 },
        { name: "未接单", sign: 333 },
        { name: "财务中心", sign: 444 },
        { name: "人员管理-所有操作", sign: 555 },
        { name: "人员管理-添加", sign: 666 },
        { name: "人员管理-修改", sign: 777 },
      ],
      checkedLists: [222],
    };
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 400px;
  height: 364px;
  opacity: 1;
  float: right;
  position: absolute;
  top: 150px;
  right: 45px;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 8px;
}
h1 {
  width: 160px;
  height: 26px;
  font-size: 20px;
  margin-top: 17px;
  margin-left: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 26px;
  color: #2a3346;
  opacity: 1;
}
.shuNum {
  width: 112px;
  height: 21px;
  font-size: 16px;
  margin-top: 25px;
  margin-left: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 36px;
  color: #2a3346;
  opacity: 1;
}
.option {
  margin-left: 30px;
  margin-top: 15px;
}
.el-checkbox {
  width: 160px;
  border: 0;
  padding-left: 0;
  margin-bottom: 10px;
  margin-right: 20px;
}
.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 30px;
  margin-top: 20px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}
</style>
