<template>
  <div>
    <div class="right-box" v-if="newyi">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="biaoti">
        {{ yiname }}:
        <span class="allnum">{{ yinumx }}</span>
      </div>
      <div class="yichangmain">
        <!-- 订单异常列表 -->
        <div class="all">
          <ul class="all-box">
            <li v-for="(item, index) in list" :key="index">
              <p class="all-p1">
                <span class="p1-span1">{{ item.spname }}</span>
                <span class="p1-span2">{{ item.time }}</span>
              </p>
              <div class="all-box2">
                <div class="yichang">
                  <p class="trailer">{{ item.rescueType }}</p>
                  <p class="status">
                    <span class="status-span">{{ item.tips }}</span>
                  </p>
                </div>

                <div
                  @click="shouli(item.rescueId)"
                  class="manage"
                  v-if="item.behavior == 1 && !getlook"
                >
                  受理
                </div>

                <div
                  @click="diaodu(item.rescueId)"
                  class="manage"
                  v-if="item.behavior == 2 && !getlook"
                >
                  调度
                </div>
                <div
                  @click="detail(item.rescueId)"
                  class="manage"
                  v-if="item.behavior == 3 && !getlook"
                >
                  处理
                </div>
                <div
                  @click="shendan(item)"
                  class="manage"
                  v-if="item.behavior == 4 && !getlook"
                >
                  审单
                </div>
                <div
                  @click="returnvisit(item.rescueId)"
                  class="manage"
                  v-if="item.behavior == 5 && !getlook"
                >
                  回访
                </div>
                <div
                  @click="dealremark(item.rescueId)"
                  class="manage"
                  v-if="item.behavior == 6 && !getlook"
                >
                  投诉
                </div>

                <div
                  @click="dealquick(item.rescueId)"
                  class="manage"
                  v-if="item.behavior == 7 && !getlook"
                >
                  处理
                </div>

                <div
                  @click="dealOcrWarn(item.rescueId)"
                  class="manage"
                  v-if="item.behavior == 15 && !getlook"
                >
                  处理
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <anomaly :istime="overtime"></anomaly>
  <state v-show="stae"></state>

  <check
    :isaudio="audio"
    :rescueIdcheck="checkrescueId"
    @checksx="sx"
    @partbtn="partbtn"
  ></check>
  <newcheck
    :isaudio2="audio2"
    :rescueIdcheck="checkrescueId"
    @checksx="sx3"
    @partbtn="partbtn"
    @guancheck="guancheck"
    v-if="yincheck"
  ></newcheck>

  <score
    v-if="huifang"
    :ishui="huifang"
    :huifangMain="huifangTit"
    :ishuiid="huiId"
    @closehui="closehui"
    @returnsx="sx2"
  ></score>
  <complain
    :iscome="come"
    :complainId="tourescueId"
    @complainsx="sx1"
  ></complain>
  <part :islook="look" :id="partrescueId" @checkbtn="checkbtn"></part>
  <ding-gai
    :isshows="alter"
    :diaodurescueid="rescueId"
    @guanyi="guanyi"
  ></ding-gai>
</template>

<script>
import anomaly from "./anomaly.vue";
import State from "./state.vue";
import DingGai from "./dingGai.vue";
import Check from "./check.vue";
import part from "./part.vue";
import Score from "./score.vue";
import complain from "./complain.vue";

import * as api from "../api/index";
import * as api1 from "../api/order";
import * as api2 from "../api/orderDetail";
import Newcheck from "./newcheck.vue";
export default {
  props: ["isun", "yitype", "yiname", "yinum", "isyichang"],
  components: {
    anomaly,
    State,
    Check,
    DingGai,
    part,
    Score,
    complain,
    Newcheck,
  },
  data() {
    return {
      look: 0,
      partrescueId: "",
      huifang: false,
      huifangTit: {},
      huiId: "",
      come: 0,
      tourescueId: "",
      add: 0,
      isShow: 1,
      overtime: 0,
      stae: false,
      listAll: [],
      zonglist: [],
      list: [],
      alter: 0,
      rescueId: 0,
      checkrescueId: "",
      audio: 0,
      audio2: 0,
      yincheck: false,
      zongname: "",
      zongnum: "",
      zongtype: "",
      type: "",
      newyi: this.isyichang,
      yinumx: this.yinum,
    };
  },

  methods: {
    async yicahng() {
      let type = this.yitype;
      let res_workUnusual = await api.newworkUnusual(type);
      console.log("新工作台订单异常：", res_workUnusual);
      this.list = res_workUnusual.data.list;
      this.yinumx = this.list.length;
    },
    async dealOcrWarn(id) {
      let res = await api.readocrwarn(id);
      this.partrescueId = id;
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
      this.unusualData(this.yitype);
    },
    // 未联系未出发到详情
    dealquick(id) {
      this.partrescueId = id;
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
      this.newyi = false;
    },
    guanyi() {
      console.log("关闭关闭", 111);
    },
    // 未回访到回访
    async returnvisit(rescue_id) {
      let res = await api1.getEvaluate(rescue_id);
      console.log("回访信息---------", res.data);
      this.huifangTit = res.data;
      this.huifang = true;
      this.huiId = rescue_id;
      console.log("回访--------------", rescue_id);
    },
    // 有投诉到投诉
    dealremark(id) {
      this.come++;
      if (this.come == 3) {
        this.come = 1;
      }
      this.tourescueId = id;
    },
    closehui() {
      this.huifang = false;
    },
    close() {
      this.newyi = false;
    },
    async sx() {
      this.unusualData(this.yitype);
      this.checkrescueId = "";
    },
    async sx2() {
      this.unusualData(this.yitype);
    },
    sx3() {
      this.unusualData(this.yitype);
    },
    //受理订单
    async shouli(id) {
      let res = await api.accept(id);
      if (res.code == 1) {
        this.unusualData(this.yitype);
      } else {
        this.$message.warning(res.message);
      }
    },
    // 未接单到指派
    diaodu(id) {
      this.alter++;
      if (this.alter == 3) {
        this.alter = 1;
      }
      this.rescueId = id;
      this.newyi = false;
    },
    // 未审核到审核
    shendan(item) {
      console.log("点击", item);
      this.checkrescueId = item.rescueId;
      if (item.isNewSh) {
        this.yincheck = true;
      } else {
        this.audio++;
        if (this.audio == 3) {
          this.audio = 1;
        }
      }
    },
    guancheck() {
      this.yincheck = false;
    },
    // 订单异常请求的数据
    async unusualData(type) {
      // let res = await api.workUnusual(type);
      let res = await api.newworkUnusual(type);
      this.list = res.data.list;
      this.yinumx = this.list.length;
      console.log(this.yinumx);
    },

    partbtn(e, btn) {
      this.partrescueId = e;
      btn.classList.add("checkcengji");
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
    },
    checkbtn(e, btn) {
      btn.classList.add("partcengji");
      this.audio++;
      if (this.audio == 3) {
        this.audio = 1;
      }
      this.look = 0;
    },
  },
  watch: {
    isun(newval) {
      // this.unusualData()
    },
    yinumx(newval) {},
    isyichang(newval) {
      this.newyi = newval;
      this.yicahng();
      console.log("监听异常", newval);
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getlook() {
      return this.$store.state.lookonly;
    },
  },
};
</script>

<style lang="less" scoped>
.all-box {
  list-style: none;
  height: 800px;
  overflow-y: scroll;
}
.all-box::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.all-box li {
  height: 110px;
  padding-top: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #f7f9fb;
}
.all-box2 {
  width: 100%;
  display: flex;
  padding: 0 30px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 10px;
}
.all-p1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  box-sizing: border-box;
}
.p1-span1 {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1d1e1d;
  opacity: 1;
}
.p1-span2 {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
}
.trailer {
  width: 90px;
  height: 22px;
  margin-top: 5px;
  text-align: center;
  line-height: 22px;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2c68ff;
  opacity: 1;
}
.status {
  margin-top: 10px;
}
.status-img {
  width: 14px;
  height: 14px;
  margin-top: 5px;
  margin-right: 10px;
  float: left;
}
.status-span,
.status-span1,
.status-span2,
.status-span3 {
  width: 98px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;

  opacity: 1;
}
.status-span {
  color: #ed5856;
}
.status-span1 {
  color: #27cc8b;
}
.status-span2 {
  color: #ff9100;
}
.status-span3 {
  color: #727e96;
}
.manage {
  float: left;
  width: 85px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  margin-top: 20px;
  // margin-left: 90px;
  border: 1px solid #2c68ff;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2c68ff;
  opacity: 1;
}
.manage:hover {
  background-color: #e8f3fe;
}

.state {
  float: right;
  margin-top: 18px;
  margin-right: 18px;
  cursor: pointer;
}

.right-box {
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  background-color: #fff;
  // padding: 28px;
  box-sizing: border-box;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
.right-nav {
  // width: 470px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  opacity: 1;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: flex-end;
}
.zong {
  font-size: 14px;
  margin-left: 10px;
}
.anomaly {
  float: left;
  width: 21px;
  height: 21px;
  margin-left: 30px;
  margin-top: 23px;
  margin-right: 10px;
}
.anomaly-span {
  width: 80px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  // line-height: 0px;
  color: #2a3346;
  opacity: 1;
  float: left;
  margin-left: 10px;
  margin-top: 35px;
}
.yichangmain {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 800px;
  float: right;
  opacity: 1;
  background-color: #fff;
  //   padding: 28px;
  margin-top: 28px;
  position: relative;
  box-sizing: border-box;
  overflow-y: scroll;
}
.right-ul {
  list-style: none;
  width: 100%;
  height: 50px;
  display: flex;
  margin: 0;
  padding-top: 15px;
  box-sizing: border-box;
  padding-left: 30px;
  box-sizing: border-box;
}
.right-ul li {
  margin-right: 5px;
}
.right-ul li a {
  width: 60px;
  height: 19px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
// .right-ul li a:hover {
//   color: #2c68ff;
// }
.active {
  width: 56px;
  height: 19px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #000 !important;

  opacity: 1;
  border-bottom: 2px solid #2c68ff;
  padding-bottom: 13px;
}
.activenum {
  color: #2c68ff !important;
}
.num {
  font-style: normal;
  color: red;
}
.zongnum {
  font-style: normal;
  color: #fff;
  font-weight: bold;
  background-color: #2c68ff;
  padding: 3px 5px;
  border-radius: 4px;
}
.biaoti {
  font-size: 20px;
  color: #2a3346;
  font-weight: bold;
  margin-top: 28px;
  margin-left: 28px;
}
.allnum {
  font-size: 20px;
  color: #ff5050;
  font-weight: bold;
}
.partcengji {
  z-index: 30 !important;
}
.checkcengji {
  z-index: 20 !important;
}
</style>
