<template>
  <div>
    <!-- 到达时效超时 -->
    <div class="box" v-show="overtimes">
      <img @click="close" src="../assets/img/ding/icon-close.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>到达时效已超时</h1>
          <img class="shua" src="../assets/img/ding/shua.png" alt="" />
          <i class="xin">刷新</i>
        </div>
        <span class="tiao"></span>
        <div class="order">
          订单号:#20212544446
          <span class="take">已接单</span>
        </div>
        <div class="order-main">
          <p class="p1">
            <span class="title">合作单位</span>
            <span class="title-main">平安保险宁波分公司</span>
            <span class="tit">服务项目</span>
            <i class="tit-main">非事故拖车</i>
          </p>
          <p class="p1">
            <span class="title">创建日期</span>
            <span class="title-main">2021-12-07 12:58:41</span>
          </p>
          <p class="p1">
            <span class="title">事故地点</span>
            <i class="tit-main">宁波市海曙区中山路1123号宁波医院停车场</i>
          </p>
          <p class="p1">
            <i class="icon">*</i>
            <span class="title">接单人员</span>
            <span class="title-main"
              >宁波小修联系服务公司/拖师傅/13708903456</span
            >
          </p>
        </div>
        <div class="owner">
          <span class="tiao1"></span>
          车主信息</div>
        <div class="owner-main">
          <p class="p2">
            <span class="title">车主姓名</span>
            <span class="title-main">张三</span>
            <span class="tit">车牌号</span>
            <span class="tit-m">浙B2113661</span>
          </p>
          <p class="p2">
            <span class="title">手机号</span>
            <span class="title-m">1582164641</span>
          </p>
        </div>
        <div class="btn">
          <button class="recycle">处理</button>
          <button class="cancel">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:["istime"],
  data() {
    return {
      overtimes:this.istime,
    };
  },
  created() {
    
  },
  methods: {
    close() {
      this.overtimes = false;
      // this.overtimes = !this.overtimes;
    }
  },
  watch:{
    istime(newval){
      console.log('---',newval);
      this.overtimes = newval
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
//   background-color: pink;
}
img {
  float: left;
  width: 103px;
  height: 103px;
  margin-top: 36px;
  margin-left: -29px;
}
.box-main {
  width: 600px;
  height: 920px;
  background: #fff;
  margin-left: 40px;
  margin-top: -21px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 200px;
  height: 26px;
  font-size: 20px;
  padding-top: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ff505a;
  opacity: 1;
}
i {
  font-style: normal;
}
.xin {
  width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 60px;
  right: 20px;
  opacity: 1;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 60px;
}
.order {
  width: 280px;
  height: 21px;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 17px;
  margin-bottom: 10px;
  line-height: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.take {
  display: inline-block;
  width: 64px;
  height: 22px;
  margin-left: 6px;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  padding-left: 7px;
  border-radius: 2px;
  color: #2c68ff;
}
.order-main {
  width: 510px;
  height: 121px;
  opacity: 1;
//   background-color: orange;
}
.p1 {
  margin-left: 30px;
  margin-bottom: 10px;
  padding-left: 7px;
}
.p2 {
  margin-left: 30px;
  margin-bottom: 10px;
}
.title {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.title-main,
.title-m,
.tit-m,
.title-l {
  width: 126px;
  height: 19px;
  font-size: 14px;

  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.title-main {
  margin-left: 30px;
}
.title-m {
  margin-left: 47px;
}
.tit-m {
  margin-left: 44px;
}
.tit {
  width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-left: 96px;
  color: #2a3346;
  opacity: 1;
}
.tit-main {
  width: 70px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-left: 30px;
  color: #2c68ff;
  opacity: 1;
}
.icon {
  width: 5px;
  height: 16px;
  font-size: 12px;
  margin-left: -5px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.owner,
.peoper {
  width: 500px;
  height: 21px;
  font-size: 16px;
  margin-top: 59px;
  line-height: 21px;
  padding-left: 7px;
  margin-left: 20px;
  margin-bottom: 10px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.owner {
  position: relative;
}
.tiao,
.tiao1 {
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.tiao {
  position: absolute;
  top: 100px;
  left: 20px;
}
.tiao1 {
  position: absolute;
  top: 3px;
  left: 0;
}



.btn {
  margin-left: 30px;
  margin-top: 40px;
}

.alter,
.cancel {
  width: 80px;
  height: 32px;
  margin-right: 20px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
}
.recycle {
  width: 80px;
  height: 32px;
  margin-right: 20px;
  background-color: #2c68ff;
  border: 0;
  color: #fff;
}
</style>
